import React from 'react';
import { navigate, graphql } from 'gatsby';
import { get, isEmpty } from 'lodash';

import createLink from 'utils/createLink';

import ArticleGridMasonry from 'components/article-grid/ArticleGridMasonry';
import { ArticleCard } from 'components/article-card/ArticleCard';
import { Content } from 'components/content/Content';
import { Map } from 'components/map/Map';
import { Link } from 'components/link/Link';
import { PageHeader } from 'components/page-header/PageHeader';
import { PrismicImage } from 'components/prismic-image/PrismicImage';
import { RegionIntro } from 'components/region-intro/RegionIntro';
import RichText from 'components/rich-text/RichText';

import { Segment } from 'components/layout/Segment';

import { MetaContainer } from 'containers/meta/MetaContainer';
import { SocialContainer } from 'containers/social/Social';
import { fragmentMeta, fragmentRegion } from 'fragments';

interface IProps {
  data: any;
}

const RegionsOfIceland = (props: IProps) => {
  const { data } = props;
  const pageData = get(data, 'prismic.allRegions_of_icelands.edges.0.node', {});
  const list = get(pageData, 'list', []);

  const renderItem = ({ link: item }: any) => {
    if (isEmpty(item)) { return; }

    const image = !isEmpty(item.image) && !isEmpty(item.image.x1) ?
      <PrismicImage image={item.image} view="x1" /> : undefined;

    return (
      <ArticleCard
        key={item._meta.uid}
        link={createLink(item)}
        heading={RichText.asText(item.title)}
        description={RichText.asText(item.list_description)}
        category={get(item, 'region.title', '')}
        image={image}
        align={get(item, 'text_alignment', 'left').toLowerCase()}
      />
    );
  };

  const onSelected = (region: string) => {
    const foundRegion = list.find((i: any) => {
      const r = get<string>(i, 'link.region.title', '');
      return r.toLowerCase() === region.toLowerCase();
    });

    if (foundRegion) {
      navigate(createLink(foundRegion.link));
    } else {
      console.warn('Region link not found');
    }
  };

  return (
    <>
      <MetaContainer data={pageData} />

      <PageHeader
        heading={RichText.asText(pageData.title)}
        text={RichText.asText(pageData.description)}
        backLink={
          !isEmpty(pageData.back_link) ? (
            <Link to={createLink(pageData.back_link)}>
              {RichText.asText(pageData.back_link.title)}
            </Link>
          ) : (
            undefined
          )
        }
      />

      <Content>
        <SocialContainer type="sidebar" facebookLink="/" twitterLink="/" pinterestLink="/" />

        <Segment>
          <RegionIntro
            map={
              <Map
                size="large"
                interactive
                region={get(pageData, 'region._meta.uid', undefined)}
                onSelected={onSelected}
              />
            }
          >
            {RichText.render(pageData.copy)}
          </RegionIntro>
        </Segment>

        <ArticleGridMasonry>{!isEmpty(list) && list.map(renderItem)}</ArticleGridMasonry>
      </Content>
    </>
  );
};



export const query = graphql`
  query regions($uid: String!, $lang: String!) {
    prismic {
      allRegions_of_icelands(uid: $uid, lang: $lang) {
        edges {
          node {
            _meta {
              ...meta
            }
            title
            description
            copy
            image
            back_link {
              _linkType

              ... on PRISMIC_Regions_of_iceland {
                _meta {
                  ...meta
                }
                title
              }
              ... on PRISMIC_Things_to_do {
                title
                _meta {
                  ...meta
                }
              }
              ... on PRISMIC_About_iceland {
                title
                _meta {
                  ...meta
                }
              }
              ... on PRISMIC_Plan_your_trip {
                title
                _meta {
                  ...meta
                }
              }
            }
            seo_title
            seo_description
            seo_image
            region {
              _linkType
              ... region
            }
            list {
              link {
                _linkType
                __typename
                ... on PRISMIC_Regions_of_iceland {
                  _meta {
                    ...meta
                  }
                  title
                  image
                  list_description
                  text_alignment
                  region {
                    _linkType
                    ... region
                  }
                }
                ... on  PRISMIC_Article {
                  _meta {
                    ...meta
                  }
                  title
                  image
                  list_description
                  text_alignment
                  region {
                    _linkType
                    ... region
                  }
                }
                ... on PRISMIC_Page_content{
                  _meta {
                    ...meta
                  }
                  title
                  image
                  list_description
                  text_alignment
                  region {
                    _linkType
                    ... region
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

RegionsOfIceland.fragments = [fragmentRegion, fragmentMeta];

export default RegionsOfIceland;
