import React, { cloneElement, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import BackArrow from 'assets/svg/icons/back-arrow.svg';
import { UIContext } from 'context/ui';

import { Container } from 'components/layout/Container';
import { ScrollIndicator } from 'components/scroll-indicator/ScrollIndicator';

import s from './PageHeader.scss';

interface IProps {
  heading: string;
  text?: string;
  backLink?: React.ReactElement<any>;
  image?: React.ReactElement<any>;
  imageCaption?: React.ReactElement<any>;
  form?: React.ReactElement<any>;
  children?: React.ReactNode;
  scrollIndicator?: boolean;
  overflow?: boolean;
  date?: string;
}

export const PageHeader = ({ heading, date, text, backLink, image, imageCaption, form, children, scrollIndicator, overflow }: IProps) => {

  const { canTransition, setHeaderTheme } = useContext<any>(UIContext);
  useEffect(() => { setHeaderTheme('dark'); }, []);

  return (
    <>
      <div className={s('pageHeader', { transition: !canTransition, overflow })}>
        <div className={s.pageHeader__container}>
          <div className={s.pageHeader__row}>
            <div className={s.pageHeader__headingWrap}>
              <h1 className={s.pageHeader__heading}>{heading}</h1>
              {date && (
              <time className={s.pageHeader__date}><FormattedMessage id="last-updated" />: {date}</time>
              )}
            </div>
            {(text || children) && (
              <div className={s.pageHeader__content}>
                {text && (<p>{text}</p>)}
                {children}
              </div>
            )}
            {children && (
              <div className={s.pageHeader__text}>
                <p>{text}</p>
              </div>
            )}
          </div>
          {image && (
          <div className={s.pageHeader__figure}>
              <>
                <div className={s.pageHeader__imageWrap}>{cloneElement(image, { className: s.pageHeader__image})}</div>
                <div className={s.pageHeader__scroll}><ScrollIndicator static /></div>
              </>

            {imageCaption && (
              <div className={s.pageHeader__imageCaption}>{cloneElement(imageCaption, { className: s.pageHeader__caption})}</div>
            )}
          </div>)}

          {backLink && (
            <div className={s.pageHeader__back}>
              {cloneElement(backLink, { className: s.pageHeader__backLink})} <BackArrow className={s.pageHeader__arrow} />
            </div>)}
        </div>

        {form && (
          <Container>
            <div className={s.pageHeader__form}>
              {form}
            </div>
          </Container>
        )}
      </div>
      {scrollIndicator && <ScrollIndicator />}
    </>
  );
};
