import React, { cloneElement } from 'react';

import { Container } from 'components/layout/Container';
import { Row } from 'components/layout/Row';

import s from './RegionIntro.scss';

interface IProps {
  map: React.ReactElement<any>;
  children: React.ReactElement<any>;
}

export const RegionIntro = ({ map, children }: IProps) => (
  <div className={s('regionIntro')}>
    <Container>
      <Row>
        <div className={s.regionIntro__map}>{map}</div>
        <div className={s.regionIntro__content}>
          {children && cloneElement(children, {
            className: s.regionIntro__richText,
          })}
        </div>
      </Row>
    </Container>
  </div>
);
